import React, {useEffect} from "react";
import {useLocation} from 'react-router-dom'
import { Link } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import styles from "./styles";

const StatementOfPrivacy = () => {
  const { pathname } = useLocation();
  const classes = styles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box display="flex" flexDirection="column">
      <Typography className={classes.privacyTitle}>
        STATEMENT OF PRIVACY
      </Typography>
      <Typography className={classes.privacyHeader}>
        October 14, 2020
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginTop: 16 }}>
        At Jasper Learning Inc. (“Jasper, “we”, “us” or “our”) your privacy is
        important to us. This Statement of Privacy explains our policies
        regarding the collection, use and disclosure of Personal Information we
        receive from you during your interactions with the products and
        services, websites, content, databases, software, technologies, and
        tools delivered by Jasper and that link or refer to these our Terms of
        Use or that are delivered through a website or server or service that
        links or refers to our Terms of Use and are provided by Jasper
        (“Services”).
      </Typography>
      <Typography className={classes.privacyDesc}>
        Jasper offers a wide range of Services used to help educate learners
        worldwide including courseware materials and software students use at
        organizations or in self-directed studies including the following
        Services:
      </Typography>
      <ul>
        <li>Our web site at Jasperactive.com; </li>
        <li>Jasperactive&trade; learning products </li>
        <li>Jasperactive for Word; </li>
        <li>Jasperactive for Word Expert </li>
        <li>Jasperactive for Excel; and </li>
        <li>Jasperactive for Excel Expert </li>
        <li>Jasperactive for PowerPoint </li>
      </ul>
      <Typography className={classes.privacyDesc}>
        This Privacy Statement does not apply to the extent we process Personal
        Data in the role of a processor or service provider on behalf of our
        customers.
      </Typography>
      <Typography className={classes.privacyDesc}>
        Please read the product-specific details in this Statement of Privacy,
        which provide additional relevant information.
      </Typography>
      <Typography className={classes.privacyDesc}>
        By using our Services, you consent to the policies described in
        this Statement of Privacy. Please read these policies carefully.
      </Typography>
      <Typography className={classes.privacyHeader}>
        PROSSESSING ACTIVITIES
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginTop: 8 }}>
        This Privacy Statement applies to the processing of Personal Data
        collected by us when you:{" "}
      </Typography>
      <ul>
        <li>
          Visit our websites that display or link to this Privacy Policy;{" "}
        </li>
        <li>Visit our social media pages;</li>
        <li>Visit our offices; or </li>
        <li>As a customer purchase our products or services; </li>
        <li>
          Receive communications from us, including emails, phone calls, texts
          or faxs; or{" "}
        </li>
        <li>
          Register for, attend or take part in our events, webinars, or
          contests; or{" "}
        </li>
      </ul>
      <Typography className={classes.privacyDesc}>
        We rely on the following legal grounds to process your Personal Data:
      </Typography>
      <Typography className={classes.privacyHeader}>
        PURPOSE AND LEGAL BASIS
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginTop: 8 }}>
        We rely on the following legal grounds to process your Personal Data:{" "}
      </Typography>
      <ul>
        <li>
          Performance of a contract.
          <br />
          <br /> We may need to collect and process your Personal Data as
          necessary to perform services subject to a contractual agreement with
          you. For example, (i) when you make an online purchase and we deliver
          you the purchased product; (ii) when you place an order with us as a
          customer by email or phone or other means; and (iii) when you register
          for a webinar or a testing program that we host.
          <br />
          <br />
        </li>
        <li>
          Consent.
          <br />
          <br /> Where we ask you for your consent, we will only collect and use
          your Personal Data to the extent you have consented to. <br />
          <br />
        </li>
        <li>
          Legitimate interests.
          <br />
          <br /> We may use your Personal Data for our legitimate interests for
          the purposes of improving our products and services; analysis of our
          performance and the performance of our products and services;
          improving the content of our web sites; and improving our programs and
          systems.
        </li>
      </ul>
      <Typography className={classes.privacyHeader}>
        COLLECTION OF USER PERSONAL INFORMATION{" "}
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginTop: 8 }}>
        We collect data from you, through our interactions with you and through
        our products. You provide some of this data directly, and some we get by
        collecting data about our interactions, including your use and
        experiences with our products and services. The data we collect depends
        on the context of your interactions with us and the choices you make,
        including your privacy settings and the products and features you use.
        <br />
        <br />
        You have choices when it comes to the technology you use and the data
        you share. When we ask you to provide personal data, you can decline.
        <br />
        <br />
        Many of our products require some personal data to provide you with a
        service. If you choose not to provide the data that is necessary to
        provide you with a product or feature, you will not be able to use that
        product or feature.
        <br />
        <br /> In addition, where we need to collect personal data by law or to
        enter into or carry out a contract with you, and you do not provide the
        data, we will not be able to enter into the contract.
        <br />
        <br /> We request information from you on our order form. To buy from
        us, you must provide contact information (such as name and shipping
        address) and financial information (such as credit card data). This
        information is used for billing purposes and to fill your orders. If we
        have trouble processing an order, we'll use this information to contact
        you.
        <br />
        <br /> Our Services collect personally identifiable information, such as
        e-mail address, name and date of birth. We also collect anonymous
        demographic information, which is not unique to you, such as your state,
        country, school type, school name, gender, and time zone. We collect
        this information for the benefit of our customers and make it available
        solely to them.
      </Typography>
      <Typography className={classes.privacyHeader}>
        USE OF USER PERSONAL INFORMATION{" "}
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginTop: 8 }}>
        We use the data we collect to provide you products and services, improve
        and update our products, or to market or send promotional communications
        to our customers and potential customers.
        <br />
        <br /> We also use the data to operate our business and services,
        including customer service and support, analysis of our performance and
        the performance of our products and services and to fulfill legal
        obligations.
        <br />
        <br /> We share your personal data only with your consent or to complete
        a transaction or provide requested products and services.
        <br />
        <br /> We use an outside shipping company to ship orders and a credit
        card processing company to bill users for products and services. These
        companies do not retain, share, store or use personally identifiable
        information for any secondary purposes beyond filling your order.
        <br />
        <br /> We do not sell, trade, or otherwise transfer to outside parties
        your personal information, except where we administer a program on
        behalf of a partner and you register with the partner. “Outside parties”
        does not include trusted third parties and subprocessors who assist us
        in operating our website, conducting our business, or servicing you, so
        long as those parties agree to comply with the provisions of our Privacy
        Policy and our obligations under our Data Processing Agreements. We may
        also release your personal information when we believe release is
        appropriate to comply with the law, enforce our site policies, or
        protect ours or other’s rights, property, or safety.{" "}
      </Typography>
      <Typography className={classes.privacyHeader}>
        COLLECTION AND USE OF STUDENT PERSONAL INFORMATION
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginTop: 8 }}>
        Students studying at organizations, such as schools, and who are not our
        direct customers or product licensees but who use our products and
        services in their studies do so under the direction of that
        organizations’ administrators or designated staff, such as instructors.
        It is at their discretion, for the purpose of tracking and communicating
        their students’ studies and progress, what personal information, if any,
        is entered into our learning systems. We support student data
        accessibility through the student’s school or educational entity.
        Retention of Data within our learning products is governed by our data
        processing agreements and our customers’ data retention policies and
        procedures.
        <br />
        <br /> We do not track our users’ activities or their Student Personal
        Information residing in our learning systems, nor do we sell, profile,
        engage in behavioral target advertising, make such information public or
        otherwise share such information except with the organizations’
        administrators and their designated staff through their ability to
        access our learning programs.
        <br />
        <br /> We subscribe to the Student Data Privacy Pledge
      </Typography>
      <Typography className={classes.privacyHeader}>
        CHILDREN’S ONLINE PRIVACY PROTECTION ACT (COPPA) COMPLIANCE
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginTop: 8 }}>
        We comply with the requirements of COPPA and do not collect personal
        information from children under 13.
        <br />
        <br /> Our Services are all directed to persons who are at least 13
        years old or older. Where our organizational customers use our products
        or educational systems to instruct or teach students under 13, it is our
        policy that the institution administrators and their designated staff is
        responsible for obtaining verifiable consent from the student’s parents
        regarding the collection and use of Students’ Personal Information by
        the school or other organization. Data Privacy Pledge
      </Typography>
      <Typography className={classes.privacyHeader}>COOKIES</Typography>
      <Typography className={classes.privacyDesc} style={{ marginTop: 8 }}>
        We use "cookies" on our web sites and systems. A cookie is a piece of
        data stored on a site visitor's hard drive to help us improve your
        access to our site and identify repeat visitors to our site. For
        instance, when we use a cookie to identify you, you would not have to
        log in a user name more than once, thereby saving time while on our
        site. Cookies can also enable us to track and target the interests of
        our users to enhance the experience on our site. Usage of a cookie is in
        no way linked to any personally identifiable information on our site.
      </Typography>
      <Typography className={classes.privacyHeader}>SECURITY </Typography>
      <Typography className={classes.privacyDesc} style={{ marginTop: 8 }}>
        We take precautions to protect your information. When you submit
        sensitive information via the Services, your information is protected
        both online and offline.
        <br />
        <br /> We secure your personal information from unauthorized access, use
        or disclosure. We secure the personally identifiable information you
        provide on computer servers in a controlled, secure environment,
        protected from unauthorized access, use or disclosure.
        <br />
        <br /> Wherever we collect sensitive information (such as credit card
        data), that information is encrypted and transmitted to us in a secure
        way through the use of encryption, such as the Secure Socket Layer (SSL)
        protocol. You can verify this by looking for a lock icon in the address
        bar and looking for "https" at the beginning of the address of the Web
        page.
        <br />
        <br /> While we use encryption to protect sensitive information
        transmitted online, we also protect your information offline. Only
        employees who need the information to perform a specific job (for
        example, billing or customer service) are granted access to personally
        identifiable information. The computers/servers in which we store
        personally identifiable information are kept in a secure environment.
      </Typography>
      <Typography className={classes.privacyHeader}>
        THIRD PARTY LINKS{" "}
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginTop: 8 }}>
        Our Services do not contain any links to outside sites other than links
        to such additional external products that you, as our customer, has
        purchased and which has been accordingly linked to such Services.
      </Typography>
      <Typography className={classes.privacyHeader}>
        CUSTOMER COMMUNICATIONS
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginTop: 8 }}>
        From time-to-time, our site, our systems or our staff may contact or
        request information via emails or telephone calls from our customers’
        administrators or contacts solely for the purposes of monitoring or
        improving the use and satisfaction of our products and services or
        providing service announcements of updates.
        <br />
        <br /> We do not email, telephone, or otherwise communicate directly
        with any user of our Services.
      </Typography>
      <Typography className={classes.privacyHeader}>YOUR RIGHTS</Typography>
      <Typography className={classes.privacyDesc} style={{ marginTop: 8 }}>
        Under privacy legislation, in the jurisdictions we serve, you have
        rights related your Personal Data that we hold. At any time, you may:
      </Typography>
      <ul>
        <li>Request access to the information we hold about you. </li>
        <li>
          Ask us to correct any inaccurate or incomplete information we hold
          about.{" "}
        </li>
        <li>
          Request us to delete your information from our records (subject to
          legitimate requests and our obligations under other laws and
          regulations.{" "}
        </li>
        <li>
          Withdraw consents you have given, where our collection and use was
          based upon such consent.{" "}
        </li>
      </ul>
      <Typography className={classes.privacyHeader}>
        SUCCESSOR ENTITIES{" "}
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginTop: 8 }}>
        Where a successor entity acquires part or of Jasper in future, the
        successor will act in accordance with our Privacy Policy and our Data
        Processing Agreements in order to continue to use or maintain any
        student personally identifiable information or will provide notice of
        changes to the customer(s), and provide the customers) an opportunity to
        opt in to the successor entity’s changed uses or sharing of student
        Personally Identifiable Information. If in that case, there is no
        opt-in, we or our successor entity will delete or de-identify the
        student’s personally identifiable information.
      </Typography>
      <Typography className={classes.privacyHeader}>
        CHANGES TO THIS STATEMENT
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginTop: 8 }}>
        We will occasionally update this Statement of Privacy to reflect our
        internal feedback, customer feedback and applicable legislation. When we
        update the Statement of Privacy we will update the date at the top of
        this Privacy Statement. We encourage you to periodically review this
        Statement of Privacy to be informed of how we are protecting any of your
        information that we hold. Where material changes to our privacy policies
        are made, we will provide advice to users in our cookies and privacy
        policy notice on our web site and user access sites including a button
        for users to provide renewed consent.
      </Typography>
      <Typography className={classes.privacyHeader}>
        SEE OUR TERMS OF SERVICES
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginTop: 8 }}>
        Please also visit our <Link to="/terms-of-service" >Terms of Service</Link> which sets-out the
        use, disclaimers, and limitations of liability governing the use of our
        products and services.
      </Typography>
      <Typography className={classes.privacyHeader}>CONTACT US</Typography>
      <Typography style={{ marginTop: 8 }}>
        If there are any questions regarding this privacy policy, you may
        contact us using the information below.
      </Typography>
      <Typography className={classes.privacyHeader}>
        If you feel that we are not abiding by this privacy policy, you should
        contact us immediately{" "}
      </Typography>
      <Link
        to="/statement-of-privacy"
        style={{ marginTop: 8, marginBottom:8 }}
        onClick={() => {
          window.location.href = "mailto:privacy@jasperactive.com";
        }}
      >
        privacy@jasperactive.com
      </Link>
      <Typography  >Phone: 1-800-668-1669 or 604-455-0070 </Typography>
      <Typography className={classes.privacyDesc} >Fax: 1-866-760-2354 </Typography>

    </Box>
  );
};

export default StatementOfPrivacy;
